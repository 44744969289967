// Example `question` props:
//   {"inputType": "text", "dropdownOptions": null, "question": "What time will you arrive?"}
//   {"inputType": "dropdown", "dropdownOptions": ["Blue", "Yellow", "Green"], "isRequired": false, "question": "Color"}
//   {"inputType": "number", "isRequired": true, "question": "How many?"}
//   {"inputType": "date", "question": "Date", "googleFormFieldID": 1066275873, "id": "abc3"}
//   {"inputType": "listBuilder", "question": "SKUs sold", "listItemInputName": "Quantity", "dropdownOptions": ["1888-0151", "1871-0181", "1588-0151"]}
//   {"inputType": "text", "googleFormFieldID": 1066275873, "id": "abc1", "isRequired": true, "prefillWithUserField": "firstName", "question": "First name"}

import React, { useState } from "react";
import { Text, View, TouchableOpacity, ScrollView } from "react-native";
import InputBox from "src/components/InputBox";
import Icon from "src/components/Icon";
import Dropdown from "src/components/Dropdown";
import DatePicker from "src/components/DatePicker";
import ListBuilderInput from "src/components/ListBuilderInput";
import moment from "moment";
import Glob from "src/globalConstants";
import Style from "src/globalStyles";
import Checkbox from "./Checkbox";

const { height } = Glob.get("dimensions");

const QUESTION_INPUT_TYPES = {
  text: {
    text: "Short answer",
    icon: "33afedab-b6e7-4b2a-aacc-7e66d6d15a5f"
  },
  number: {
    text: "Number",
    icon: "5bfb1d90-ad32-473a-a8f4-7febe4206ade"
  },
  dropdown: {
    text: "Dropdown",
    icon: "fc1a4221-05fa-47c1-864a-b6268101cfdb"
  },
  date: {
    text: "Date",
    icon: "calendar"
  }
  // listBuilder is an additional type that's displayable, but not editable yet
};

const PillButton = ({
  text,
  onPress,
  icon,
  style = {},
  textStyle = {},
  iconSize = 20
}) => {
  return (
    <TouchableOpacity
      onPress={onPress}
      style={{
        backgroundColor: "#e0e0e0",
        borderRadius: 100,
        paddingHorizontal: 10,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        marginRight: 10,
        height: 30,
        ...style
      }}
    >
      {icon && <Icon icon={icon} color="gray" size={iconSize} />}
      <Text
        style={{
          color: "gray",
          marginLeft: 5,
          fontWeight: "bold",
          ...textStyle
        }}
      >
        {text}
      </Text>
    </TouchableOpacity>
  );
};

const AnswerableQuestion = ({
  question,
  index,
  value,
  onChangeValue,
  user = {},
  formPortalID = null
}) => {
  // Note: Make sure to set value with onChangeValue to avoid infinite loop
  if (!value && user) {
    if (
      question.prefillWithSavedFormAnswers &&
      user.savedFormAnswers &&
      formPortalID &&
      user.savedFormAnswers[formPortalID] &&
      user.savedFormAnswers[formPortalID][question.id]
    ) {
      onChangeValue(user.savedFormAnswers[formPortalID][question.id]);
    } else if (
      question.prefillWithUserField &&
      user[question.prefillWithUserField]
    ) {
      onChangeValue(user[question.prefillWithUserField]);
    }
  }
  if (question.inputType === "dropdown") {
    return (
      <Dropdown
        key={index}
        header={question.question}
        showAsOptional={!question.isRequired}
        value={value}
        items={question.dropdownOptions.map((option) => ({
          value: option,
          text: option
        }))}
        onSelect={onChangeValue}
      />
    );
  }
  if (question.inputType === "listBuilder") {
    return (
      <ListBuilderInput
        key={index}
        header={question.question}
        showAsOptional={!question.isRequired}
        listItems={value}
        listItemInputName={question.listItemInputName}
        dropdownOptions={question.dropdownOptions.map((option) => ({
          value: option,
          text: option
        }))}
        onUpdateListItems={onChangeValue}
        placeholder={question.dropdownPlaceholder}
      />
    );
  }
  if (question.inputType === "date") {
    if (!value) onChangeValue(moment().format("MM/DD/YYYY"));
    return (
      <DatePicker
        header={question.question || "Date"}
        selectedDate={value ? moment(value, "MM/DD/YYYY").toDate() : new Date()}
        onDateChange={(newDate) => {
          onChangeValue(moment(newDate).format("MM/DD/YYYY"));
        }}
        validRange={
          question.restrictDateInputToPast3Days
            ? {
                startDate: moment()
                  .subtract(2, "days")
                  .startOf("day")
                  .toDate(),
                endDate: moment()
                  .startOf("day")
                  .toDate()
              }
            : null
        }
      />
    );
  }
  return (
    <InputBox
      key={index}
      header={question.question}
      showAsOptional={!question.isRequired}
      onChangeText={onChangeValue}
      value={value}
      style={{ marginTop: 15 }}
      keyboardType={question.inputType === "number" ? "numeric" : "default"}
      isInvalid={
        value.length > 0 && question.inputType === "number" && !Number(value)
      }
      placeholder={question.inputType === "number" ? "Enter a number" : ""}
    />
  );
};

const EditableQuestion = ({
  question,
  index,
  questions,
  onChangeQuestions,
  formPortalID
}) => {
  const [isPickingInputType, setIsPickingInputType] = useState(false);
  const [isShowingMoreOptions, setIsShowingMoreOptions] = useState(false);

  const addDropdownOption = () => {
    onUpdateQuestionValue("dropdownOptions", [
      ...(question.dropdownOptions || []),
      ""
    ]);
  };

  const onUpdateQuestionValue = (key, value) => {
    // Deep copy
    const updatedQuestions = questions.map((q, i) =>
      i === index ? { ...q, [key]: value } : q
    );
    onChangeQuestions(updatedQuestions);
  };

  const onUpdateQuestionValues = (keyValues) => {
    const updatedQuestions = questions.map((q, i) =>
      i === index ? { ...q, ...keyValues } : q
    );
    onChangeQuestions(updatedQuestions);
  };

  let typeButtonText = "Set Type";
  if (question.inputType) {
    if (question.inputType in QUESTION_INPUT_TYPES) {
      typeButtonText = QUESTION_INPUT_TYPES[question.inputType]?.text;
    } else {
      typeButtonText = "Custom";
    }
  }

  return (
    <View
      key={question.id}
      style={{
        marginBottom: 20,
        flexDirection: "column",
        backgroundColor: "#f3f3f3",
        paddingHorizontal: 8,
        paddingBottom: 10,
        borderRadius: 8
      }}
    >
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <View style={{ flex: 1, alignItems: "center" }}>
          <InputBox
            placeholder="Enter question to ask"
            value={question.question}
            onChangeText={(text) => onUpdateQuestionValue("question", text)}
            flexibleWidth
            style={{ flex: 1 }}
          />
        </View>
        {!formPortalID && (
          <TouchableOpacity
            onPress={() => {
              const updatedQuestions = questions.filter((_, i) => i !== index);
              onChangeQuestions(updatedQuestions);
            }}
            style={{ padding: 10 }}
          >
            <Icon
              icon="ec79581a-c8a5-4021-a5c1-82cc8e51ea00"
              color="gray"
              size={20}
            />
          </TouchableOpacity>
        )}
      </View>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          marginTop: 5
        }}
      >
        <View>
          {isPickingInputType ? (
            <View
              style={{
                width: "100%",
                backgroundColor: "white",
                paddingHorizontal: 10,
                borderRadius: 8
              }}
            >
              <Dropdown
                value={question.inputType}
                items={Object.entries(QUESTION_INPUT_TYPES).map(
                  ([key, { text }]) => ({
                    value: key,
                    text
                  })
                )}
                onSelect={(value) => {
                  setIsPickingInputType(false);
                  onUpdateQuestionValues({
                    inputType: value,
                    prefillWithSavedFormAnswers: null,
                    prefillWithUserField: null,
                    ...(value === "dropdown" && !question.dropdownOptions
                      ? { dropdownOptions: [""] }
                      : {})
                  });
                }}
                startOpen
                onCollapse={() => setIsPickingInputType(false)}
                flexibleWidth
              />
            </View>
          ) : (
            <PillButton
              text={typeButtonText}
              onPress={() => setIsPickingInputType(true)}
              icon={
                question.inputType
                  ? QUESTION_INPUT_TYPES[question.inputType]?.icon
                  : null
              }
            />
          )}
        </View>
        {!isPickingInputType && (
          <View style={{ justifyContent: "flex-end" }}>
            <PillButton
              text={question.isRequired ? "Required" : "Optional"}
              icon={
                question.isRequired
                  ? "736e8e38-cfa7-4f9f-aa8a-9376b56f9573"
                  : null
              }
              iconSize={12}
              onPress={() =>
                onUpdateQuestionValue("isRequired", !question.isRequired)
              }
            />
          </View>
        )}
      </View>
      {question.inputType === "dropdown" && !isPickingInputType && (
        <ScrollView
          style={{
            marginTop: 10,
            marginLeft: 0,
            borderWidth: 0.5,
            borderColor: "gray",
            borderRadius: 5,
            padding: 10,
            backgroundColor: "white",
            maxHeight: 0.33 * height
          }}
        >
          {question.dropdownOptions?.map((option, optionIndex) => (
            <View
              key={optionIndex}
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 10,
                marginRight: 25
              }}
            >
              <InputBox
                placeholder="Enter dropdown option"
                value={option}
                onChangeText={(text) => {
                  const updatedOptions = [...question.dropdownOptions];
                  updatedOptions[optionIndex] = text;
                  onUpdateQuestionValue("dropdownOptions", updatedOptions);
                }}
                openStyle
                flexibleWidth
              />
              <TouchableOpacity
                onPress={() => {
                  const updatedOptions = question.dropdownOptions.filter(
                    (_, i) => i !== optionIndex
                  );
                  onUpdateQuestionValue("dropdownOptions", updatedOptions);
                }}
                style={{ padding: 10 }}
              >
                <Icon
                  icon="aa149ca4-4c0d-4a50-b442-5541da551067"
                  color="gray"
                  size={20}
                />
              </TouchableOpacity>
            </View>
          ))}
          <PillButton
            text="Add Dropdown Option"
            icon="cde42390-8051-4509-9bfb-462ff507af63"
            onPress={addDropdownOption}
            style={{ marginBottom: 20 }}
          />
        </ScrollView>
      )}
      {!!isShowingMoreOptions && (
        <View style={{ flex: 1 }}>
          {(question.inputType === "text" ||
            question.inputType === "number" ||
            question.inputType === "dropdown") && (
            <>
              <Text style={[Style.get("headerText"), { marginTop: 20 }]}>
                Pre-fill Options
              </Text>
              <Checkbox
                text={
                  question.inputType === "date"
                    ? "Pre-fill with today's date"
                    : "Don't pre-fill the answer"
                }
                radio
                checked={
                  !question.prefillWithSavedFormAnswers &&
                  !question.prefillWithUserField
                }
                onChange={() => {
                  onUpdateQuestionValues({
                    prefillWithSavedFormAnswers: null,
                    prefillWithUserField: null
                  });
                }}
              />
              <Checkbox
                text="Pre-fill with user's previous answer"
                radio
                checked={question.prefillWithSavedFormAnswers}
                onChange={() => {
                  onUpdateQuestionValues({
                    prefillWithSavedFormAnswers: true,
                    prefillWithUserField: null
                  });
                }}
              />
              {question.inputType === "text" && (
                <>
                  <Checkbox
                    text={`Pre-fill with ${
                      question.prefillWithUserField
                        ? "the user's..."
                        : "specific user info"
                    }`}
                    radio
                    checked={!!question.prefillWithUserField}
                    onChange={() => {
                      onUpdateQuestionValues({
                        prefillWithUserField: "firstName",
                        prefillWithSavedFormAnswers: null
                      });
                    }}
                  />
                  {!!question.prefillWithUserField && (
                    <View style={{ paddingLeft: 35 }}>
                      <Checkbox
                        text="First name"
                        radio
                        checked={question.prefillWithUserField === "firstName"}
                        onChange={() => {
                          onUpdateQuestionValues({
                            prefillWithUserField: "firstName",
                            prefillWithSavedFormAnswers: null
                          });
                        }}
                      />
                      <Checkbox
                        text="Last name"
                        radio
                        checked={question.prefillWithUserField === "lastName"}
                        onChange={() => {
                          onUpdateQuestionValues({
                            prefillWithUserField: "lastName",
                            prefillWithSavedFormAnswers: null
                          });
                        }}
                      />
                      <Checkbox
                        text="Email address"
                        radio
                        checked={question.prefillWithUserField === "email"}
                        onChange={() => {
                          onUpdateQuestionValues({
                            prefillWithUserField: "email",
                            prefillWithSavedFormAnswers: null
                          });
                        }}
                      />
                      <Checkbox
                        text="Phone number"
                        radio
                        checked={
                          question.prefillWithUserField === "phoneNumber"
                        }
                        onChange={() => {
                          onUpdateQuestionValues({
                            prefillWithUserField: "phoneNumber",
                            prefillWithSavedFormAnswers: null
                          });
                        }}
                      />
                    </View>
                  )}
                </>
              )}
            </>
          )}
          {question.inputType === "date" && (
            <>
              <Text style={[Style.get("headerText"), { marginTop: 20 }]}>
                Input Restrictions
              </Text>
              <Checkbox
                text="Restrict date range to past 3 days"
                checked={question.restrictDateInputToPast3Days}
                onChange={() =>
                  onUpdateQuestionValue(
                    "restrictDateInputToPast3Days",
                    !question.restrictDateInputToPast3Days
                  )
                }
              />
            </>
          )}
          <InputBox
            header="Google Form Field ID"
            placeholder="1234567890"
            value={`${question.googleFormFieldID || ""}`}
            onChangeText={(text) =>
              onUpdateQuestionValue("googleFormFieldID", text)
            }
            flexibleWidth
            style={{ flex: 1 }}
          />
        </View>
      )}
      {!!formPortalID && (
        <PillButton
          text={`${isShowingMoreOptions ? "Hide" : "Show"} Advanced Options`}
          icon={
            isShowingMoreOptions
              ? "35eeba6c-2f77-4616-9dd5-e11ff6a22732" // chevron-up
              : "aba344c6-435e-4da8-965f-54ed12a04792" // chevron-down
          }
          onPress={() => setIsShowingMoreOptions(!isShowingMoreOptions)}
          style={{ marginTop: 10, backgroundColor: "rgba(0,0,0,0)" }}
        />
      )}
    </View>
  );
};

export default function Question({
  isEditing,
  question,
  index,
  value = "",
  questions = [],
  onChangeValue = () => {},
  onChangeQuestions = () => {},
  user = null,
  formPortalID = null
}) {
  if (!isEditing) {
    return (
      <AnswerableQuestion
        question={question}
        index={index}
        value={value}
        onChangeValue={onChangeValue}
        user={user}
        formPortalID={formPortalID}
      />
    );
  }

  return (
    <EditableQuestion
      question={question}
      index={index}
      questions={questions}
      onChangeQuestions={onChangeQuestions}
      user={user}
      formPortalID={formPortalID}
    />
  );
}
